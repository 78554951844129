import React, { ReactElement } from 'react'
import Layout from '../../components/Layout/Layout'
import ArticlePage from '../../components/ArtOfLiving/ArticlePage/ArticlePage'

import './ArtOfLivingArticle.scss'

const ArtOfLivingArticleTemp = ({ pageContext: { data } }: any): ReactElement => {
  return (
    <Layout>
      <div className="aol-article-wrapper">
        <ArticlePage data={data} />
      </div>
    </Layout>
  )
}

export default ArtOfLivingArticleTemp
