import React, { ReactElement, useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { kebabCase } from 'lodash'
import SEO from '../../SEO/SEO'
import './ArticlePage.scss'

const ArticlePage = (data: any): ReactElement => {
  const getWidth = () => (typeof window !== `undefined` ? window.innerWidth : 0)
  const [isMobile, setIsMobile] = useState<boolean>(getWidth() <= 800)
  const [isTabletPro, setIsTabletPro] = useState<boolean>(getWidth() === 1024)

  useEffect(() => {
    if (!isMobile && !isTabletPro) {
      navigate(`/the-art-of-living?${kebabCase(data.data.slug)}/`)
    }
  }, [])

  return (
    <>
      <SEO
        title={data.data?.contentDefault ? data.data?.contentDefault : `${data.data?.title} - Ayala Land Premier`}
        description={data.data?.description?.replace(/\n/g, '')}
        image={data?.data?.image}
      />
      <div className="aol-article__desktop-container">
        <p>Loading article...</p>
      </div>
      <div className="aol-article__mobile-container">
        <div className="aol-article">
          {data.data.components.map((component: any, index: number) => {
            return (
              <div className="aol-article__fold" key={index}>
                <div className="aol-article__fold-bg">
                  <img loading="lazy" src={component.image} />
                </div>
                <div className="aol-article__fold-description">
                  {/* Article title */}
                  {index == 0 && <h1 dangerouslySetInnerHTML={{ __html: data.data.title }}></h1>}

                  {/* Component title */}
                  {component.title !== '' && index !== 0 && <h1 dangerouslySetInnerHTML={{ __html: component.title }}></h1>}

                  <p dangerouslySetInnerHTML={{ __html: component.description }}></p>
                </div>
              </div>
            )
          })}

          <div className="aol-article__back-wrapper">
            <Link to="/the-art-of-living/">Back to stories</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticlePage
